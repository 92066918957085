<template>
  <v-snackbar v-model="isShow" small color="primary" text right bottom :timeout="+'3000'">
    <v-icon color="primary" class="mr-2">mdi-check-bold</v-icon>
    {{ message }}
    <v-btn color="primary" small text @click="isShow = false">
      {{ $t("Close") }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "info-snackbar",
  props: ["show", "message"],
  data: function() {
    return {};
  },
  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    }
  }
};
</script>

<style></style>
