<template>
  <v-snackbar v-model="isShow" right bottom multi-line vertical :timeout="+'20000'">
    <p v-for="(error, i) in errors" :key="i">{{ error }}</p>
    <v-btn color="red" small text @click="isShow = false">
      {{ $t("Close") }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "errors-snackbar",
  props: ["show", "errors"],
  data: function() {
    return {};
  },
  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      }
    }
  }
};
</script>

<style></style>
