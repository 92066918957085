<template>
  <v-main class="main-bgc">
    <v-container fluid>
      <v-layout align-center justify-center fill-height>
        <v-row>
          <v-col md-6>
            <v-skeleton-loader transition="scale-transition" :loading="loading" type="paragraph">
              <v-card>
                <v-card-title>
                  <h1>
                    {{ $t(`Account has been successfully confirmed !`) }}
                  </h1>
                </v-card-title>
                <v-card-actions>
                  <v-btn to="/profile" color="primary" small>{{ $t("Go to profile") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-main>
</template>

<script>
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import { confirmEmail } from "@/api";
import { setUserAuthToken } from "@/auth/auth";
export default {
  components: { ErrorsSnackbar, InfoSnackbar },
  data: () => ({
    loading: true,
    showWarnings: false,
    showInfo: false,
    infoMessage: "",
    warnings: []
  }),
  methods: {},
  mounted() {
    if (this.$route.query.confirmation_token) {
      confirmEmail(this.$route.query)
        .then(r => {
          if (Object.prototype.hasOwnProperty.call(r.data, "token")) {
            setUserAuthToken(r.data.token);
            this.loading = false;
          }
        })
        .catch(err => {
          if (
            Object.prototype.hasOwnProperty.call(err, "data") &&
            Object.prototype.hasOwnProperty.call(err.data, "token")
          ) {
            setUserAuthToken(err.data.token);
            this.loading = false;
          } else {
            this.warnings =
              err.response.status !== 500
                ? err.response.data.errors
                : [this.$t("Something went wrong, please try again later")];
            this.showWarnings = true;
          }
        });
    }
  }
};
</script>

<style></style>
